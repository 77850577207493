.swiper-button-prev,
.swiper-button-next {
  color: white;
}

.swiper-pagination-bullet {
  border: 1.5px solid white;
  background: transparent;
  width: 10px;
  height: 10px;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: white;
}
