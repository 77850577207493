/* NoteCardSkeleton.css */
.skeleton-loader {
  background: linear-gradient(90deg, #b0b0b0 25%, #d0d0d0 50%, #b0b0b0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s ease-in-out infinite;
  opacity: 0.9;
}

/* Synchronizing the start of the animation for all elements */
.card {
  animation-delay: 0s;
}

.card:nth-child(1) .skeleton-loader {
  animation-delay: 0.1s;
}
.card:nth-child(2) .skeleton-loader {
  animation-delay: 0.2s;
}
.card:nth-child(3) .skeleton-loader {
  animation-delay: 0.3s;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
